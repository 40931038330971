import {useRef, useState} from "react";
import {useStateContext} from "../../contexts/ContextProvider";
import LockClosedIcon from "@heroicons/react/20/solid/LockClosedIcon";
import {login} from "../../api/userApiClient";

export default function LoginPage() {
    const [errors, setErrors] = useState(null);
    const {setUser, setToken} = useStateContext();
    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const handleSubmit = (ev) => {
        ev.preventDefault()

        setErrors(null)

        const email = emailRef.current.value;
        const password = passwordRef.current.value;

        login(email, password)
            .then(
                (data) => {
                    setUser({
                        name  : data.user.name,
                        email : data.user.email,
                        type  : data.user.type,
                    })

                    setToken(data.token)
                })
            .catch(
                err => {
                    setErrors({
                        email : [err.message]
                    })
                })
    }

    return (
        <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <div>
                        <img
                            className="mx-auto h-12 w-auto"
                            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                            alt="Pegasus Pole"
                        />
                        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                            Login to your account
                        </h2>
                        {errors && <div style={{color: 'red'}}>
                            {
                                Object.keys(errors).map(key => (
                                    <p key={key}>{errors[key][0]}</p>))
                            }
                        </div>
                        }
                    </div>
                    <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div>
                                <label htmlFor="email-address" className="sr-only">
                                    Email address
                                </label>
                                <input
                                    ref={emailRef}
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Email address"
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">
                                    Password
                                </label>
                                <input
                                    ref={passwordRef}
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Password"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true"/>
                                </span>
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}